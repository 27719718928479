// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeDash,
    placeCircle,
    placeLogo,
    placePlay,
    placeErase
} from '../../../common/edliy_utils-geometric';
const Boxes = {
    box1: function () {
	  JXG.Options.board.minimizeReflow = 'none';
    JXG.Options.text.highlight=false;
    JXG.Options.text.fixed=true;
    JXG.Options.image.highlight=false;
    JXG.Options.text.cssDefaultStyle='fontFamily:Oswald';
    JXG.Options.line.highlight=false;
    JXG.Options.arc.highlight=false;
    JXG.Options.point.highlight=false;
    JXG.Options.point.showInfoBox=false;
      var brd2 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-1, 10.5, 14., -4.5],
        keepaspectratio: true, axis:false, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false}, showCopyright:false, showNavigation:false});
    makeResponsive(brd2);
      brd2.options.layer['line'] =6;
      brd2.options.layer['segment'] =6;
	    brd2.options.layer['point'] =6;
      brd2.options.layer['polygon'] =2;
      brd2.options.layer['image'] =8;
      ////////////////////////////////////////////////////
	  //brd2.create('text', [5, 10, '<b>Projectile Motion</b>'], {fontSize:function(){return 32*brd2.canvasHeight/800}});
    placeTitle(brd2, 'Projectile Motion');
    placeLogo(brd2);
    var play = placePlay(brd2,'left');
    var eraser = placeErase(brd2);
	  //
	  var circc=brd2.create('arc', [[0,0],[5, 0], [0.00,5]],{visible:true, strokeWidth:0.5, strokeColor:'black', dash:1, center:{visible:false}, radiuspoint:{visible:false}, anglepoint:{visible:false}});
	  //
      var lev =brd2.create('glider', [5, 5, circc], {face:'circle', size:3, name:'Launch Angle (Drag Me!)', strokeColor:'black', label:{fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

      brd2.create('polygon', [[-1, -0], [-1, -5], [22, -5], [22, -0]],{vertices:{visible:false, fixed:true}, withLines:false, fillColor:'grey'});
	  //
      var vel = brd2.create('slider', [[0, -2],[4, -2],[5, 8, 11.5]], {baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'Launch Speed',size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
	  //
	  var ang =brd2.create('angle', [[1,0], [0,0], lev],{visible:false});
	  //
    var ball =brd2.create('point', [0, 0.0], {name:'', size:1, trace:true, fixed:true});
	  //
    brd2.create('image', ['/assets/player.svg', [-0.25, -1.4],[2,2]],{fixed:true, shadow:false});
	  //
	  var x =0; var y=0; var tt=0; var dt=0.015; var i=0; var j=0;
	  var run =function(){
	  if(i==0){
	  ball.clearTrace();}
	  i =1;
	  if(x<=vel.Value()*vel.Value()*Math.sin(2*ang.Value())/9.8 && j>0){
		tt +=dt;
		x = vel.Value()*Math.cos(ang.Value())*tt;
		y = vel.Value()*Math.sin(ang.Value())*tt -0.5*9.8*tt*tt;}
    ball.moveTo([x,y]);
		if(x<=vel.Value()*vel.Value()*Math.sin(2*ang.Value())/9.8 && j>0){
		setTimeout(run, 0);}
		else{return;}
        }
	  play.on('down', function(){x=0; y=0.0; tt=0; i=0; j=1; run();});
	  eraser.on('down', function(){x=0; y=0.0; tt=0; i=0; j=0; ball.clearTrace(); ball.moveTo([0,0])});
	  vel.on('down', function(){x=0; y=0; j=0});
	  lev.on('down', function(){x=0; y=0; j=0});
	  //
	  //brd2.create('curve',[function(t){return vel.Value()*Math.cos(ang.Value())*t},
	//					function(t){return vel.Value()*Math.sin(ang.Value())*t -0.5*9.8*t*t},
	 // 0, function(){return ball.X()/vel.Value()/Math.cos(ang.Value())}],{strokeWidth:1, dash:1,  strokeColor:'black', visible:true});
//
	  brd2.create('image', ['/assets/ball.svg',
    [function(){return x-0.25}, function(){return lev.Y()*x/lev.X()-0.5*9.8*x*x/(vel.Value()*Math.cos(ang.Value()))/(vel.Value()*Math.cos(ang.Value()))-0.25}],[0.5,0.5]],{highlight:false});
      //
	  brd2.create('arrow', [[0,0], lev],{dash:1, strokeColor:'grey'});
	  //
	  brd2.create('segment', [[0.0,0], [function(){return x;}, 0.0]],{strokeColor:'black', strokeWidth:1, dash:1});
	 //
	 brd2.create('segment', [[function(){return vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6}, 0.0], [function(){return vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6}, function(){return lev.Y()*Math.min(x, vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)/lev.X()-0.5*9.8*Math.min(x,vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)*Math.min(x,vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)/(vel.Value()*Math.cos(ang.Value()))/(vel.Value()*Math.cos(ang.Value()))}]],{strokeColor:'black', strokeWidth:1, dash:1});
	  //
	  brd2.create('text', [function(){return x*0.5}, -.25,  function(){return (x).toFixed(2)+' m'}], {fixed:true, fontSize:function(){return 12*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

	  brd2.create('text', [function(){return vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6 +0.15}, function(){return 0.5*(lev.Y()*Math.min(x, vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)/lev.X()-0.5*9.8*Math.min(x,vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)*Math.min(x,vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)/(vel.Value()*Math.cos(ang.Value()))/(vel.Value()*Math.cos(ang.Value())));}, function(){return (lev.Y()*Math.min(x, vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)/lev.X()-0.5*9.8*Math.min(x,vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)*Math.min(x,vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)/(vel.Value()*Math.cos(ang.Value()))/(vel.Value()*Math.cos(ang.Value()))).toFixed(2) +' m'}], {fixed:true, fontSize:function(){return 12*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	   //
      brd2.create('text', [1, 8.5, '1. Select a launch angle. 2. Select a launch speed. 3. Tap on the play button to throw.'], {fixed:true, fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	  //
      brd2.create('text', [2, -1, function(){return 'Launch Angle =' + (180*ang.Value()/Math.PI).toFixed(1) + '(<sup>o </sup>)'}], {fixed:true, fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      }
}
export default Boxes;
