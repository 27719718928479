<template>
  <div>
    <h3 ref="ia">
      Projectile Motion
    </h3>
    <p>
      Projectile motion is the motion of an object thrown or projected into the air.
      During such motion, the object travels along a parabolic path.
      During the flight, the only force acting on the object is the Earth's gravity.
      An object in projectile motion is known as a projectile, and the path traced by the object is called the trajectory.
      Think of a football player throwing the ball to another player.
      The motion of the ball, as it travels from one player to another, is an example of projectile motion.
    </p>
    <h3 ref="ca">
      Equation of Motion for a Projectile
    </h3>
    <p>
      Let's say the object is thrown with an initial velocity of \(v\) in a direction \(\alpha\) from the horizon.
      The air resistance is assumed to be negligible. Then, the only force acting on the object is due to gravity, which acts in the downward direction.
      The horizontal velocity of the object at \(t=0\) is given as $$u_x (t=0) = v \cos \alpha$$
      Since there is no force acting on the object in the horizontal direction, the horizontal velocity of the object \(u_x\) remains constant at
      all times during the flight of the object. $$u_x (t) = u_x(0) = v \cos \alpha$$ <br>
      The vertical velocity of the object at \(t=0\) is given as $$u_y (t=0) = v \sin \alpha$$ The overall motion of a projectile can be divided into two phases: the ascent phase (during which the object goes up) and the descent phase (during which the object comes down). The path traversed by the projectile is called its trajectory.
    </p>
    <ul style="list-style-type: square;">
      <li>
        <h5>Duration of Ascent t<sub>ac</sub></h5>
        During the ascent phase, the vertical velocity of the projectile decreases with time due to gravity. Let's say the ascent phase lasts until \(t = t_{ac}\). Then at any \(t \le t_{ac}\): $$u_y(t) = v \sin \alpha - g t$$ The ascent phase ends when the vertical velocity of the projectile becomes zero, i.e., $$u_y\vert_{t=t_{ac}} = 0 = v \sin \alpha - g t_{ac}$$ Thus, we obtain the duration of ascent as: $$t_{ac} = \frac{v \sin\alpha}{g}$$ Note that another way to look at \(t_{ac}\) is the time taken for the projectile to reach the peak height on its trajectory.
      </li>
      <li>
        <h5>Total Time of Flight t<sub>tot</sub></h5>
        The total time of flight is the duration for which the projectile remains in the flight (i.e., above the earth's surface). It is given as the sum of the duration of accent and the duration of descent. $$t_{tot} = t_{ac} + t_{dc} = \frac{2 v \sin\alpha}{g}$$
      </li>
      <li>
        <h5>Range of the Projectile R</h5>
        The total horizontal distance traveled by the projectile during its flight is called the range (R) of the projectile. Since the horizontal velocity of the projectile remains constant during the flight,
        the range of a projectile can simply be calculated by multiplying the horizontal velocity with the total time of flight.
        $$R = u_x(0) t_{tot} =\frac{2 v^2 \sin\alpha \cos\alpha}{g} = \frac{ v^2 \sin2\alpha}{g} $$
      </li>
      <li>
        <h5>Peak Point on the Trajectory h<sub>max</sub></h5>
        Using the equation of motion, the vertical distance traveled by the projectile as a function of time can be expressed as $$y(t) = u_y(0)t - \frac{1}{2}gt^2 = v \sin \alpha t - \frac{1}{2}gt^2$$ The peak of the trajectory is reached at \(t = t_{ac} = \frac{v \sin\alpha}{g} \). Thus, we obtain: $$h_{max} = y(t=t_{ac}) = \frac{(v^2 \sin^2 \alpha)}{2g}$$
      </li>
    </ul>
    <h3 id="mgk" ref="pg">
      MagicGraph: Trajectory of a Football in Projectile Motion
    </h3>
    <p>Through this MagicGraph, students will learn:</p>
    <ul style="list-style-type: square;">
      <li>To calculate the range, maximum height, and trajectory, of a projectile.</li>
      <li>To calculate the location and velocity of a projectile at different points in its trajectory.</li>
    </ul>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5> Follow this link <a class="icn" href="https://edliy.com/magic"> <i class="fa fa-external-link-square" /></a> to learn how MagicGraphs help students accelerate their learning curve.</h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ProjectileMotion',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Projectile Motion';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Projectile Motion', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Equation of Projectile Motion', img:'/assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'MagicGraph: Trajectory of a Football',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Projectile Motion',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Projectile Motion'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
